import { useState } from "react"
import { Link } from "react-router-dom"
import { ContactUs } from "../utils"
import Logo from "./Logo"
import phone from "../images/phone.svg"
import { navbar } from "../data/data"
import { FaBars } from "react-icons/fa"

export default function Header() {
  const [links] = useState(navbar)

  const handleOpenMenu = () => {
    const navbar = document.querySelector(".navbar")
    const listItems = document.querySelectorAll(".list-item")

    navbar.classList.toggle("open")
    listItems.forEach((item) =>
      item.addEventListener("click", () => navbar.classList.remove("open"))
    )
  }

  return (
    <>
      <header className="header absolute w-full max-width p-5">
        <div className="flex flex-wrap items-center justify-between w-full">
          <div className="lg:absolute top-5 z-50">
            <Link to="/">
              <Logo />
            </Link>
          </div>

          <nav className="navbar">
            <ul className="flex flex-col items-center text-white lg:flex-row lg:justify-end">
              {links.map(({ id, title, url }) => (
                <li
                  key={id}
                  className="list-item lg:mr-5 xl:mr-10 text-lg my-4 lg:text-base lg:my-0"
                >
                  <Link to={url}>{title}</Link>
                </li>
              ))}
              <li className="list-item lg:mr-5 xl:mr-10 text-lg my-4 lg:text-base lg:my-0">
                <a href="tel:+44444444444" className="flex items-center">
                  <img src={phone} alt="" className="mr-3" /> Telephone
                </a>
              </li>
              <li className="list-item">
                <Link to="/help">
                  <ContactUs />
                </Link>
              </li>
            </ul>
          </nav>

          <div className="lg:hidden">
            <button onClick={handleOpenMenu}>
              <FaBars className="text-yellow-300 text-3xl" />
            </button>
          </div>
        </div>
      </header>
    </>
  )
}
