export default function AboutDesc() {
  return (
    <>
      <div className="section max-width text-center">
        <p className="mb-5 gray">
          With our big-picture vision of the industry, we have found success in
          truly bringing together the perfect blend of efficiency and safe
          trading with world-class service and support. With our Trade Core
          Platform and Back Office, we ensure that our clients can always stay
          ahead of the game by providing them with a seamless trading
          experience. Feel free to explore our Trading Platform and CRM System.
          We are proud to offer a trading experience that will give you an
          advantage over your competitors. Our Trading Core system helps you
          manage risks and close more deals.
        </p>
        <p className="gray">
          At White Label Trading Platform 1, we are dedicated to providing
          quality service and client satisfaction that exceeds expectations.
          With an in-house support team, and specialized account manager built
          to drive your business forward, we want to take care of your business
          and make you a part of our family.
        </p>
      </div>
    </>
  )
}
