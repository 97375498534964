import image from "../../images/back-office-liquidity.jpg"

export default function BackOfficeLiquidity() {
  return (
    <>
      <section className="max-width px-5 2xl:px-0">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:items-center lg:gap-10">
          <article>
            <h3 className="green font-light lg:text-4xl mb-5 lg:mb-10">
              Liquidity Providers
            </h3>
            <p>
              Our integration platform allows you to connect to the top
              liquidity providers in the industry. We work with a range of
              liquidity providers that understand the needs of our clients and
              can provide the best rates for both buy and sell-side liquidity.
              We give you access to both market-leading providers, and a choice
              to integrate your preferred one for added efficiency.
            </p>
          </article>

          <article>
            <img src={image} alt="Liquidity Provider" className="w-full" />
          </article>
        </div>
      </section>
    </>
  )
}
