import { Link } from "react-router-dom";
import logo from "../images/WLTP_logo_new.png";

export default function Logo() {
  return (
    <>
      <div>
        <Link to="/">
          <img
            src={logo}
            alt="White Label Trading"
            className="w-20 sm:w-24 lg:w-32"
          />
        </Link>
      </div>
    </>
  );
}
