import hero from "../../images/trade-hero.jpg"
import { GetStarted } from "../../utils"

export default function TradeHero() {
  return (
    <>
      <section className="gradient hero">
        <div className="md:grid grid-cols-2 gap-5 lg:gap-10 xl:max-w-7xl xl:mx-auto">
          <article className="mb-10 md:mb-0">
            <h1>Trade With the Fastest All-in-One Platform</h1>
            <p className="mb-5 text-white lg:mb-10 font-light">
              Start trading today with our one-stop trading platform. We help
              individual and institutional traders check real-time quotes and
              place trades in one place.
            </p>
            <GetStarted />
          </article>

          <article>
            <img src={hero} alt="Trade With the Fastest All-in-One Platform" />
          </article>
        </div>
      </section>
    </>
  )
}
