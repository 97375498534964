import hero from "../../images/tech-hero.jpg"
import { GetStarted } from "../../utils"

export default function TechHero() {
  return (
    <>
      <section className="gradient hero">
        <div className="md:grid grid-cols-2 gap-5 lg:gap-10 xl:max-w-7xl xl:mx-auto">
          <article className="mb-10 md:mb-0">
            <h1>Quality Tech Services in One Platform</h1>
            <p className="mb-5 text-white lg:mb-10 font-light">
              Through a unique set of services offered, White Label Trading
              Platform Company can help your organization with its needs in
              cybersecurity, website design and development, and much more. Our
              company will work with you to understand your business needs and
              provide the best solutions.
            </p>
            <GetStarted />
          </article>

          <article>
            <img src={hero} alt="Quality Tech Services in One Platform" />
          </article>
        </div>
      </section>
    </>
  )
}
