import play from "../images/play-button.svg"

export default function GetStarted() {
  return (
    <>
      <a
        href="#get-started"
        className="flex items-center text-white font-bold lg:text-lg hover:opacity-95 transition-all duration-150"
      >
        <img src={play} alt="" className="mr-5 w-5 md:w-8" /> Get started today!
      </a>
    </>
  )
}
