import { Form } from "../components"
import TechCards from "./components/TechCards"
import TechHero from "./components/TechHero"
import TechWeb from "./components/TechWeb"

export default function Technology() {
  return (
    <>
      <section>
        <TechHero />
        <TechCards />

        <div className="form__gradient section">
          <h2 className="max-width mb-10">
            Want to learn more about our{" "}
            <span className="lg:block">Tech Services?</span>{" "} Let us contact you!
          </h2>
          <Form />
        </div>

        <TechWeb />
      </section>
    </>
  )
}
