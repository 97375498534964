import { useState } from "react"
import { homeCards } from "../data/data"
import play from "../images/play-button.svg"

export default function HomeCards() {
  const [cards] = useState(homeCards)

  return (
    <>
      <section className="section max-width">
        {cards.map(({ id, title, image, desc }) => (
          <article
            key={id}
            className="home__article tech__web__article mb-16 lg:mb-20"
          >
            <div className="image">
              <img src={image} alt={title} />
            </div>
            <div className="text">
              <h3 className="green font-light mt-7 mb-3 md:text-4xl lg:mt-0 lg:mb-10 lg:w-2/3">
                {title}
              </h3>
              <p>{desc}</p>
              <a
                href="get-started"
                className="font-bold text-gray-900 flex items-center mt-5 md:hidden"
              >
                <img src={play} alt="" className="mr-5 w-6" /> Get Started Today
              </a>
            </div>
          </article>
        ))}
      </section>
    </>
  )
}
