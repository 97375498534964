import { useState } from "react"
import { backOfficeAbout } from "../../data/data"

export default function BackOfficeCards() {
  const [cards] = useState(backOfficeAbout)

  return (
    <>
      <section className="section px-5 2xl:px-0 max-width">
        <h2 className="green">About the platform</h2>
        <div className="pt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 lg:gap-10 xl:gap-20">
          {cards.map(({ id, title, image }) => (
            <article
              key={id}
              className="green-card flex flex-col items-center justify-center"
            >
              <img src={image} alt={title} className="w-16" />
              <div>
                <h3 className="text-white text-lg mt-5 text-center">{title}</h3>
              </div>
            </article>
          ))}
        </div>
      </section>
    </>
  )
}
