import { useState } from "react"
import { techWebCards } from "../../data/data"

export default function TechWeb() {
  const [cards] = useState(techWebCards)

  return (
    <>
      <section className="max-width pt-10 lg:pt-20 px-5 2xl:px-0">
        <h2 className="green">Web Design and Development</h2>
        <p className="text-center mb-10 lg:mb-20 bg-gray-100 px-1 py-2 lg:max-w-4xl lg:mx-auto">
          Let us boost your online presence. Providing a wide variety of
          pre-made templates and Tailored-{" "}
          <span className="hidden lg:block">
            made designs, we will have the perfect design made just for you! A
            simple and effective method
          </span>{" "}
          to help prospective clients find you with no hassle at all.
        </p>

        <div>
          {cards.map(({ id, title, desc, image }) => (
            <article
              key={id}
              id="tech-web"
              className="tech__web__article mb-16 lg:mb-40"
            >
              <div className="image">
                <img src={image} alt={title} />
              </div>
              <div className="text">
                <h3 className="green font-light my-6 md:text-4xl lg:mt-0 lg:mb-5">
                  {title}
                </h3>
                <p>{desc}</p>
              </div>
            </article>
          ))}
        </div>
      </section>
    </>
  )
}
