import { useState } from "react"
import { helpCards } from "../../data/data"

export default function HelpCards() {
  const [cards] = useState(helpCards)

  return (
    <>
      <section className="section lg:max-w-5xl lg:mx-auto">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 md:place-items-center">
          {cards.map((card) => (
            <article key={card.id}>
              <img
                src={card.image}
                alt="Help page cards"
                className="block mx-auto w-40 h-40 md:w-44 md:h-44 lg:w-48 lg:h-48"
              />
            </article>
          ))}
        </div>
      </section>
    </>
  )
}
