import Form from "./Form"
import HomeCards from "./HomeCards"
import HomeCards2 from "./HomeCards2"
import HomeHero from "./HomeHero"
import HomeSlides from "./HomeSlides"

export default function Home() {
  return (
    <>
      <section>
        <HomeHero />
        <HomeCards />

        <div className="form__gradient section">
          <h2 className="max-width">
            Let's take your business{" "}
            <span className="lg:block">to a new level</span>
          </h2>
          <Form />
        </div>

        <HomeSlides />
        <HomeCards2 />
      </section>
    </>
  )
}
