import React from "react";
import {
  RiTelegramLine,
  RiFacebookCircleLine,
  RiWhatsappLine,
} from "react-icons/ri";
import { AiOutlineSkype } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";

export const navbar = [
  {
    id: uuidv4(),
    title: "Back Office",
    url: "/back-office",
  },
  {
    id: uuidv4(),
    title: "Trading Platforms",
    url: "/trading-platform",
  },
  {
    id: uuidv4(),
    title: "Technologies",
    url: "/technologies",
  },
  {
    id: uuidv4(),
    title: "Help",
    url: "/help",
  },
  {
    id: uuidv4(),
    title: "About Us",
    url: "/about-us",
  },
];

export const homeCards = [
  {
    id: uuidv4(),
    title: "Our Outstanding Trading Platform",
    desc: "We offer powerful, prompts and reliable trading platform to meet your needs. We provide the latest financial technology to follow market dynamics and help you achieve your goals, ahead of market trends.",
    image: "./images/Robots_web_trader_mobile_desktop_1.png",
  },
  {
    id: uuidv4(),
    title: "Back Office",
    desc: "Our Back Office solution allows you to manage your business in only one place. You can have the complete overview which can be easily assessed on any device. Our Back Office solution meets the needs of any company, regardless of the size.",
    image: "./images/home-back-office.jpg",
  },
  {
    id: uuidv4(),
    title: "Simple API Integrations",
    desc: "Our API solutions provides for simplified and seamless integration of 3rd party providers, including Affiliate platforms, PSPs, VOIP etc.",
    image: "./images/home-simple.jpg",
  },
];

export const homeSlides = [
  {
    id: uuidv4(),
    title: "Our Back Office System for your Brokerage business",
    button: "Back Office",
    image: "./images/slide-back-office.jpg",
    desc: "White Label Trading Platform 1's Back Office is a seamless, integrated solution for managing all of your Sales & Marketing activities. It connects with all of the major trading platforms, payment systems, and other cloud software solutions to provide a streamlined end-to-end experience for your clients and brokerage staff. It is the key to growing your business. Give your people more time to sell, manage and grow your client base with a solution designed specifically to work with multiple systems across your organization. Client marketing, Sales, Onboarding, Engagement, Service, and Staff Accounting, are connected seamlessly, while referral and incentive management boost production across all lines of business.",
    list: ["Automated activities for minimal cost;", "Build-in Task Manager;"],
  },
  {
    id: uuidv4(),
    title: "Risk Management",
    button: "Risk Management",
    image: "./images/slide-risk.jpg",
    desc: "For everyday work in the industry, brokerage systems have to face certain difficulties. Through our risk management tools, you get to check and monitor technical and market risk in real-time. Our risk control function could be used to put a stop to your over-limit trading behavior. Empower the users to control the risk of the financial system, improve efficiency by managing or monitoring risks instead of working in silos isolated in a department. We help you manage, monitor, and optimize your P&L & performance to reach higher growth levels.",
    list: [
      "Margin Call Accounts;",
      "Market exposure;",
      "Trader Exposure;",
      "Bridge Log;",
    ],
  },
  {
    id: uuidv4(),
    title: "Trading Platform",
    button: "Trading Platform",
    image: "./images/slide-trading.jpg",
    desc: "Our friendly web application provides an easy-to-use trade execution platform for all our users. It serves as your one-stop destination to check quotes and place trades, see account balances, P&L, and other key performance metrics such as funding, reporting, and more. It is popular with traders for its cutting-edge, and easy-to-use features.",
    list: [
      "Build-in advanced trading tools for analyzing trends and market changes;",
      "Suitable for traders from all backgrounds and experiences;",
      "Tailored for the clients' needs and preferences;",
      "Reliable software with smooth performance;",
    ],
  },
  {
    id: uuidv4(),
    title: "PSP / Banking API Integration",
    button: "PSP/Banking",
    image: "./images/slide-psp.jpg",
    desc: "We strive to create a seamless and secure payment experience while offering a wide range of payment options to ensure your clients are not inconvenienced. Through seamless integration with our PSP and API Banking Integration, we provide your business with a safe and convenient way of accepting secure online payments. Our solution is designed to be compatible with the latest technologies from virtually any device to create an appealing user experience for your customers.",
    list: [
      "Internal and external Transfers;",
      "Crypto Transactions;",
      "Crypto Balance;",
      "Transactions;",
    ],
  },
  {
    id: uuidv4(),
    title: "Reports",
    button: "Reports",
    image: "./images/slide-reports.jpg",
    desc: "We provide powerful monitoring, reporting, and optimizing tools to help you unlock valuable information that can help ensure the success of your campaign. With our click reporting module, you can generate fully customizable click reports to monitor campaigns in great depth. Using our reporting tools, you can unlock valuable information and utilize it to generate various valuable reports.",
    list: [
      "View patterns and trends in sales, as well as in marketing;",
      "Manage risk and compliance;",
      "Visualize comparisons;",
    ],
  },
];

export const homeCards2 = [
  {
    id: uuidv4(),
    title: "Seamless connection between your Clients and Brokerage Staff",
    image: "./images/card-1.svg",
  },
  {
    id: uuidv4(),
    title:
      "Custom notification center that will keep you updated wherever you are",
    image: "./images/card-2.svg",
  },
  {
    id: uuidv4(),
    title: "Keep track of all activity in one place",
    image: "./images/card-4.svg",
  },
  {
    id: uuidv4(),
    title: "Payment Systems assisted by incentive and referral solutions",
    image: "./images/card-3.svg",
  },
  {
    id: uuidv4(),
    title:
      "Smooth KYC process, Account Registration, and Funding, Live chat, and promotions",
    image: "./images/card-5.svg",
  },
];

export const footer = [
  {
    id: uuidv4(),
    title: "Our Products",
    url: "/trading-platform",
    subLinks: [
      {
        title: "Trading Platform",
        url: "/trading-platform",
      },
      {
        title: "Back Office",
        url: "/back-office",
      },
      {
        title: "Websites",
        url: "/technologies#tech-web",
      },
      {
        title: "Cybersecurity",
        url: "/technologies#tech-web",
      },
      {
        title: "Customer Support",
        url: "/help",
      },
      {
        title: "Payment Solutions",
        url: "/back-office#cards",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "About Us",
    url: "/about-us",
    subLinks: [
      {
        title: "About Us",
        url: "/about-us",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Contacts",
    url: "/help",
    subLinks: [
      {
        title: "Email",
        url: "mailto:",
      },
      {
        title: "Telephone Number",
        url: "tel:",
      },
    ],
  },
];

export const footerSocials = [
  {
    id: uuidv4(),
    image: <RiTelegramLine className="text-6xl" />,
    url: "https://telegram.com",
  },
  {
    id: uuidv4(),
    image: <RiFacebookCircleLine className="text-6xl" />,
    url: "https://facebook.com",
  },
  {
    id: uuidv4(),
    image: <RiWhatsappLine className="text-6xl" />,
    url: "https://whatsapp.com",
  },
  {
    id: uuidv4(),
    image: <AiOutlineSkype className="text-6xl" />,
    url: "https://skype.com",
  },
];

export const backOfficeHero = [
  {
    id: uuidv4(),
    title: "For brokers",
    image: "./images/back-office-brokers.svg",
  },
  {
    id: uuidv4(),
    title: "For investors",
    image: "./images/back-office-investors.svg",
  },
  {
    id: uuidv4(),
    title: "For startups",
    image: "./images/back-office-startups.svg",
  },
];

export const backOfficeAbout = [
  {
    id: uuidv4(),
    title: "Compliance",
    image: "./images/back-office-compliance.svg",
  },
  {
    id: uuidv4(),
    title: "Reports Center",
    image: "./images/back-office-reports.svg",
  },
  {
    id: uuidv4(),
    title: "In-house Support Team",
    image: "./images/back-office-in-house.svg",
  },
  {
    id: uuidv4(),
    title: "Live Trading Data",
    image: "./images/back-office-live.svg",
  },
  {
    id: uuidv4(),
    title: "Algo Trading API",
    image: "./images/back-office-algo.svg",
  },
  {
    id: uuidv4(),
    title: "Social Trading API",
    image: "./images/back-office-social.svg",
  },
  {
    id: uuidv4(),
    title: "Client Area",
    image: "./images/back-office-client.svg",
  },
  {
    id: uuidv4(),
    title: "Anti-Fraud Management",
    image: "./images/back-office-anti-fraud.svg",
  },
];

export const backOfficeCards2 = [
  {
    id: uuidv4(),
    title: "Stocks",
    image: "./images/back-office-stocks.svg",
  },
  {
    id: uuidv4(),
    title: "Cryptocurrency",
    image: "./images/back-office-crypto.svg",
  },
  {
    id: uuidv4(),
    title: "Indices",
    image: "./images/back-office-indices.svg",
  },
  {
    id: uuidv4(),
    title: "Commodities",
    image: "./images/back-office-commodities.svg",
  },
  {
    id: uuidv4(),
    title: "Forex",
    image: "./images/back-office-forex.svg",
  },
  {
    id: uuidv4(),
    title: "CFDs",
    image: "./images/back-office-cfds.svg",
  },
];

export const backOfficeArticles = [
  {
    id: uuidv4(),
    title: "Risk Management",
    image: "./images/back-office-risk.jpg",
    text: [
      "Currency exposure",
      "Instrument exposure",
      "Trader exposure",
      "Margin Call Accounts",
      "Bridge Log",
      "Live Prices",
    ],
  },
  {
    id: uuidv4(),
    title: "Reports",
    image: "./images/back-office-reports.jpg",
    text: [
      "Big Winners",
      "Big Losers",
      "Profit & Loss",
      "Social copiers",
      "Social Leaders",
      "IP Fraud",
    ],
  },
  {
    id: uuidv4(),
    title: "Sales",
    image: "./images/back-office-sales.jpg",
    text: [
      "Leads, Users & Customers",
      "First Time Deposit",
      "Sales, Partners & Affiliates",
      "Revenues, Fees, Payments",
      "Trading Account",
      "New customer, Lead or User",
    ],
  },
  {
    id: uuidv4(),
    title: "Payment Solutions",
    image: "./images/back-office-payment.jpg",
    text: [
      "Transactions",
      "Payment Gateways",
      "Cryptos Balance",
      "Internal Transfers",
      "External Transfers",
      "Bitcoin Transactions",
    ],
  },
];

export const tradeCards = [
  {
    id: uuidv4(),
    title: "Multiple instruments to choose from",
    image: "./images/trade-multiple.png",
  },
  {
    id: uuidv4(),
    title: "Fast Order Executions",
    image: "./images/trade-fast.png",
  },
  {
    id: uuidv4(),
    title: "User friendly navigations",
    image: "./images/trade-user.png",
  },
];

export const tradeConvert = [
  {
    id: uuidv4(),
    title: "Enjoy Our Swift Trading Platform",
    desc: "Open your door to the world of trading with MT4. With advanced functionality, superior reliability, and enhanced security, this is the competitive edge that you can count on to give you the edge in your market. Open your door to a competitive edge.",
    image: "./images/trade-enjoy.jpg",
  },
  {
    id: uuidv4(),
    title: "MT4 Client Terminal",
    desc: "Welcome to your brand new MT4 client terminal. We've kept the most popular forex platform simple and easy to navigate, allowing you to have a relaxing trading experience. Our MT4 is designed to work with multiple operating systems and devices, giving you peace of mind that you can make the most out of it. Whether you are a novice or an expert trader, our fully branded MT4 is created specifically for you.",
    image: "./images/trade-client.jpg",
  },
  {
    id: uuidv4(),
    title: "MT4 Manager",
    desc: "The MT4 Manager maintains all required regulatory parameters and provides your clients with a straightforward, user-friendly interface that can be conveniently accessed through a Web browser on a desktop or mobile device. We offer a fully-functional and configurable trading MT4 Manager that allows you to have fast and easy access to all your trading account data, account preferences, trade history, charts, and more.",
    image: "./images/trade-manager.jpg",
  },
];

export const techCards = [
  {
    id: uuidv4(),
    title: "IT Services",
    image: "./images/tech-it.svg",
  },
  {
    id: uuidv4(),
    title: "Installation Setup",
    image: "./images/tech-installation.svg",
  },
  {
    id: uuidv4(),
    title: "Hosting Services",
    image: "./images/tech-hosting.svg",
  },
  {
    id: uuidv4(),
    title: "Software Licences and Solutions",
    image: "./images/tech-software.svg",
  },
  {
    id: uuidv4(),
    title: "Firewalls",
    image: "./images/tech-firewalls.svg",
  },
  {
    id: uuidv4(),
    title: "Branded Emails",
    image: "./images/tech-branded.svg",
  },
];

export const techWebCards = [
  {
    id: uuidv4(),
    title: "Pre-made Template Website",
    desc: "We provide pre-made templates for your website, fully customized with your preferred layout and graphics for a unique look. We have a wide variety of pre-made templates that will fit your business. You'll still have complete control over your website —we just make it easy to get the job done. We will make a package based on your needs and deliver it in 24 hours.",
    image: "./images/tech-premade.jpg",
  },
  {
    id: uuidv4(),
    title: "Tailored-made Website",
    desc: "Have a website idea you're just waiting to create? We can make it happen. We are ready to design your site, to meet your specific needs. Your new site will connect with your customers via social media and email, making business easy. Let's get started on building your website today.",
    image: "./images/tech-tailoredmade.jpg",
  },
  {
    id: uuidv4(),
    title: "Cybersecurity",
    desc: "You can count on us to protect your company from all vulnerabilities, including the latest in software and hardware attacks. With a team of highly trained employees and a vast network of contacts, we can complete any task quickly and accurately. We provide military-grade security for your company's data. With our talent, skills, resources, and history, we can provide quality security at prices most businesses can afford.",
    image: "./images/tech-cybersecurity.jpg",
  },
  {
    id: uuidv4(),
    title: "Branded emails",
    desc: "We offer a web-based, fully-branded solution that enables you to customize your user interface for a comprehensive yet streamlined experience. It seamlessly integrates with your existing MT4/MT5 trading platform and through an easy drag-and-drop process creates a consistent brand experience across the board.",
    image: "./images/tech-emails.jpg",
  },
];

export const helpCards = [
  {
    id: uuidv4(),
    image: "./images/help-care.svg",
  },
  {
    id: uuidv4(),
    image: "./images/help-consulting.svg",
  },
  {
    id: uuidv4(),
    image: "./images/help-success.svg",
  },
];

export const aboutCards = [
  {
    id: uuidv4(),
    title: "Technological Innovations",
    desc: "Our state-of-the-art and innovative technologies help us to succeed in a competitive market with high-quality services that are delivered at highly affordable prices.",
    image: "./images/about-bulb.svg",
  },
  {
    id: uuidv4(),
    title: "Customer Satisfaction",
    desc: "Our customers' satisfaction is an absolute must for us and we will spare no efforts to attain it.",
    image: "./images/about-heart.svg",
  },
  {
    id: uuidv4(),
    title: "Self-Improvement",
    desc: "We believe that tomorrow we can do better than today. We stay ahead with trends and ensure to improve our services every day.",
    image: "./images/about-rocket.svg",
  },
];
