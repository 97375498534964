import hero from "../images/home-hero.jpg"
import { GetStarted } from "../utils"

export default function HomeHero() {
  return (
    <>
      <section className="gradient hero">
        <div className="flex flex-col-reverse md:grid grid-cols-2 gap-5 lg:gap-10 xl:max-w-7xl xl:mx-auto">
          <article className="mb-10 md:mb-0">
            <h1 className="my-10 md:my-0">
              The Most Powerful Trading Platform in the Industry
            </h1>
            <p className="mb-5 text-white lg:mb-10 hidden md:block">
              We're the all-in-one brokerage solution for businesses that need
              to draw from multiple investment and risk management solutions to
              achieve their business objectives.
            </p>
            <GetStarted />
          </article>

          <article>
            <img src={hero} alt="Our Brand Story" className="shadow-2xl" />
          </article>
        </div>
      </section>
    </>
  )
}
