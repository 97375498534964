import Form from "../components/Form"
import BackOfficeHero from "./components/BackOfficeHero"
import BackOfficeCards from "./components/BackOfficeCards"
import BackOfficeLiquidity from "./components/BackOfficeLiquidity"
import BackOfficeCards2 from "./components/BackOfficeCards2"
import BackOfficeArticles from "./components/BackOfficeArticles"

export default function BackOffice() {
  return (
    <>
      <section>
        <BackOfficeHero />
        <BackOfficeCards />

        <div className="form__gradient section">
          <h2 className="max-width lg:mb-16">
            Ready to get started? Contact us{" "}
            <span className="lg:block">today for your Back Office and</span>{" "}
            Trading Platforms package.
          </h2>
          <Form />
        </div>

        <BackOfficeArticles />
        <BackOfficeLiquidity />
        <BackOfficeCards2 />
      </section>
    </>
  )
}
