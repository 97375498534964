import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ScrollToTop, Header, Home, Footer } from "./components"
import {
  About,
  BackOffice,
  Error,
  Help,
  Technology,
  TradingPlatform,
} from "./pages"

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />

      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/back-office" element={<BackOffice />}></Route>
        <Route path="/trading-platform" element={<TradingPlatform />}></Route>
        <Route path="/technologies" element={<Technology />}></Route>
        <Route path="/help" element={<Help />}></Route>
        <Route path="/about-us" element={<About />}></Route>
        <Route path="*" element={<Error />}></Route>
      </Routes>

      <Footer />
    </BrowserRouter>
  )
}

export default App
