import { useState } from "react"
import { homeCards2 } from "../data/data"

export default function HomeCards2() {
  const [cards] = useState(homeCards2)

  return (
    <>
      <section className="home__cards section max-width lg:relative lg:my-20">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {cards.map(({ id, title, image }) => (
            <article key={id} className="home__cards__two">
              <img
                src={image}
                alt={title}
                className="block mx-auto w-20 h-20 md:w-32 lg:w-40 lg:h-32"
              />
              <p className="text-center mt-3 lg:w-64 lg:mx-auto font-semibold text-gray-800">
                {title}
              </p>
            </article>
          ))}
        </div>
      </section>
    </>
  )
}
