import { useState } from "react"
import { backOfficeCards2 } from "../../data/data"

export default function BackOfficeCards2() {
  const [cards] = useState(backOfficeCards2)
  return (
    <>
      <section className="section xl:max-w-5xl xl:mx-auto">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 lg:gap-10 lg:pt-20">
          {cards.map(({ id, title, image }) => (
            <article
              key={id}
              className="green-card lg:w-36 lg:h-28 lg:flex lg:flex-col lg:items-center lg:justify-center"
            >
              <img src={image} alt={title} className="block mx-auto" />
              <h3 className="text-white text-base lg:text-lg text-center mt-5 font-light">
                {title}
              </h3>
            </article>
          ))}
        </div>

        <div className="pt-10 lg:pt-20 text-center">
          <a href="#get-started" className="btn__request__demo">
            Consult an expert
          </a>
        </div>
      </section>
    </>
  )
}
