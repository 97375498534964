import TradeHero from "./components/TradeHero"
import { Form } from "../components"
import TradeConvert from "./components/TradeConvert"
import TradeCards from "./components/TradeCards"

export default function TradingPlatform() {
  return (
    <>
      <section>
        <TradeHero />
        <TradeCards />

        <div className="form__gradient section">
          <h2 className="max-width">
            Want more details?{" "}
            <span className="block">Let us contact you!</span>
          </h2>
          <Form />
        </div>

        <TradeConvert />
      </section>
    </>
  )
}
