import hero from "../../images/about-hero.jpg"
import { GetStarted } from "../../utils"

export default function AboutHero() {
  return (
    <>
      <section className="gradient hero">
        <div className="md:grid grid-cols-2 gap-5 lg:gap-10 lg:items-center xl:max-w-7xl xl:mx-auto">
          <article className="mb-10 md:mb-0">
            <h1>Our Brand Story</h1>
            <p className="text-white font-light">
              We have significant experience in the start-up business, which
              means we know exactly what you need to succeed. We can grow with
              your company, providing services for anything from start-up to an
              already established organization that needs additional products.
            </p>
            <p className="text-white font-light mb-5 lg:mb-10">
              Since our beginning, we have aimed to remain true to our core
              values— seeking the best possible technology solutions for clients
              and brokers.
            </p>
            <GetStarted />
          </article>

          <article>
            <img src={hero} alt="Our Brand Story" />
          </article>
        </div>
      </section>
    </>
  )
}
