import { useState } from "react"
import { aboutCards } from "../../data/data"

export default function AboutCards() {
  const [cards] = useState(aboutCards)

  return (
    <>
      <section className="section px-5 2xl:px-0 max-width">
        <h2 className="green">Our Fundamental Values</h2>
        <div className="pt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:flex lg:justify-center lg:flex-wrap">
          {cards.map(({ id, title, desc, image }) => (
            <article
              key={id}
              className="green-card lg:mr-10 lg:flex items-center w-full article__width"
            >
              <img
                src={image}
                alt={title}
                className="w-20 mb-5 lg:w-28 lg:mr-10 lg:mb-0"
              />
              <div>
                <h3 className="text-white">{title}</h3>
                <p className="text-white font-light">{desc}</p>
              </div>
            </article>
          ))}
        </div>
      </section>
    </>
  )
}
