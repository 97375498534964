import { useState } from "react"
import { backOfficeArticles } from "../../data/data"
import ellipse from "../../images/ellipse.svg"

export default function BackOfficeArticles() {
  const [cards] = useState(backOfficeArticles)

  return (
    <>
      <section className="max-width pt-10 lg:pt-20 px-5 2xl:px-0" id="cards">
        {cards.map(({ id, title, image, text }) => (
          <article key={id} className="tech__web__article mb-10 lg:mb-20">
            <div className="image">
              <img src={image} alt={title} />
            </div>
            <div className="text">
              <h3 className="green font-light mb-5 md:text-4xl lg:mb-10 mt-5">
                {title}
              </h3>

              <ul className="grid grid-cols-2">
                {text.map((t, index) => (
                  <li
                    key={index}
                    className="flex items-center mb-5 text-sm md:text-base"
                  >
                    <img src={ellipse} alt="" className="mr-2 w-3" /> {t}
                  </li>
                ))}
              </ul>
            </div>
          </article>
        ))}
      </section>
    </>
  )
}
