import { Link } from "react-router-dom"

export default function Error() {
  return (
    <>
      <section className="gradient hero">
        <div className="flex flex-col items-center justify-center text-center px-5 2xl:px-0 xl:max-w-6xl xl:mx-auto">
          <h1>Error 404 | Page Not Found</h1>
          <p className="mb-5 text-white">
            Sorry, the page you are looking for does not exist or may have
            moved.
          </p>
          <Link to="/" className="btn-yellow">
            Back to Homepage
          </Link>
        </div>
      </section>
    </>
  )
}
