import Form from "../components/Form"
import AboutCards from "./components/AboutCards"
import AboutDesc from "./components/AboutDesc"
import AboutHero from "./components/AboutHero"

export default function About() {
  return (
    <>
      <section>
        <AboutHero />
        <AboutDesc />
        <div className="form__gradient section">
          <h2 className="max-width">
            Need help? <span className="block">Send us a message!</span>
          </h2>
          <Form />
        </div>
        <AboutCards />
      </section>
    </>
  )
}
