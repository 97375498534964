import { useState } from "react"
import { tradeCards } from "../../data/data"

export default function TradeCards() {
  const [cards] = useState(tradeCards)

  return (
    <>
      <section className="section lg:max-w-5xl lg:mx-auto">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 md:items-center">
          {cards.map((card) => (
            <article key={card.id}>
              <img
                src={card.image}
                alt="Trading Platforms cards"
                className="w-24 h-22 block mx-auto mb-5"
              />
              <h4 className="uppercase text-gray-900 text-center font-bold mt-5 text-xl">
                {card.title}
              </h4>
            </article>
          ))}
        </div>
      </section>
    </>
  )
}
