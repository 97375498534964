import { GetStarted } from "../../utils"
import hero from "../../images/help-hero.jpg"

export default function HelpHero() {
  return (
    <>
      <section className="gradient hero">
        <div className="md:grid grid-cols-2 gap-5 lg:gap-10 xl:max-w-7xl xl:mx-auto">
          <article className="mb-10 md:mb-0">
            <h1>Impressive Customer Service Provider</h1>
            <p className="mb-5 text-white lg:mb-10 font-light">
              By providing high-quality and professional services to our
              clients, we are trusted by our users. We believe in always putting
              our customer's needs first. Our aim is to achieve the highest
              level of customer satisfaction by always exceeding customer
              expectations at every opportunity, while offering value, quality,
              selection, and distinctive service.
            </p>
            <GetStarted />
          </article>

          <article>
            <img src={hero} alt="Impressive Customer Service Provider" />
          </article>
        </div>
      </section>
    </>
  )
}
