import { useState } from "react"
import { backOfficeHero } from "../../data/data"
import { GetStarted } from "../../utils"
import hero from "../../images/back-office-hero.jpg"

export default function BackOfficeHero() {
  return (
    <>
      <section className="gradient hero">
        <div className="md:grid grid-cols-2 gap-5 lg:gap-10 xl:max-w-7xl xl:mx-auto pb-10 lg:pb-20">
          <article className="mb-10 md:mb-0">
            <h1>Time Is Precious — Use It to Your Advantage!</h1>
            <p className="mb-5 text-white lg:mb-10">
              Do you ever feel buried in paperwork? No matter how organized you
              are, our Back Office system can save you time and effort. Our Back
              Office system is designed to work with you, so it's completely
              mobile. Run your whole practice on the go! We built the Back
              Office with special attention to customer service and exceptional
              quality. Contact us today!
            </p>
            <GetStarted />
          </article>

          <article>
            <img src={hero} alt="Our Brand Story" />
          </article>
        </div>

        <div>
          <HeroCards />
        </div>
      </section>
    </>
  )
}

const HeroCards = () => {
  const [cards] = useState(backOfficeHero)
  return (
    <>
      <ul className="back__office__hero">
        <ul className="flex flex-col items-center justify-center sm:flex-row md:grid md:grid-cols-3 md:gap-5 md:items-start pt-10 lg:pt-20 md:place-items-center md:max-w-4xl md:mx-auto">
          {cards.map(({ id, title, image }) => (
            <li
              key={id}
              className="text-white text-xl mb-5 sm:mb-0 sm:mr-5 md:mr-0 md:flex md:items-center lg:text-2xl"
            >
              <img
                src={image}
                alt={title}
                className="block mx-auto mb-5 md:mb-0 md:mr-5"
              />
              {title}
            </li>
          ))}
        </ul>
      </ul>
    </>
  )
}
