import { useState } from "react"
import { techCards } from "../../data/data"

export default function TechCards() {
  const [cards] = useState(techCards)

  return (
    <>
      <section className="section cards__max__width">
        <h2 className="green">About our Technology Services</h2>
        <div className="pt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 lg:gap-14">
          {cards.map(({ id, title, image }) => (
            <article key={id} className="green-card">
              <img src={image} alt={title} className="block mx-auto" />
              <h3 className="text-center text-lg mt-5 text-white">{title}</h3>
            </article>
          ))}
        </div>
      </section>
    </>
  )
}
