import { useState } from "react"
import { Link } from "react-router-dom"
import { footerSocials } from "../data/data"
import Logo from "./Logo"

export default function Footer() {
  return (
    <>
      <footer className="gradient section">
        <div className="max-width">
          <div>
            <Logo />
          </div>

          <nav>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-5">
              <ul>
                <li className="font-bold text-white text-2xl mt-5 block">
                  Our Products
                </li>
                <li className="my-3 light-text font-light lg:text-lg">
                  <Link to="/trading-platform">Trading Platform</Link>
                </li>
                <li className="my-3 light-text font-light lg:text-lg">
                  <Link to="/back-office">Back Office</Link>
                </li>
                <li className="my-3 light-text font-light lg:text-lg">
                  <Link to="/technologies#tech-web">Websites</Link>
                </li>
                <li className="my-3 light-text font-light lg:text-lg">
                  <Link to="/technologies#tech-web">Cybersecurity</Link>
                </li>
                <li className="my-3 light-text font-light lg:text-lg">
                  <Link to="/help">Customer Support</Link>
                </li>
                <li className="my-3 light-text font-light lg:text-lg">
                  <Link to="/back-office#cards">Payment Solutions</Link>
                </li>
              </ul>

              <ul>
                <li className="font-bold text-white text-2xl mt-5 block">
                  About Us
                </li>
                <li className="my-3 light-text font-light lg:text-lg">
                  <Link to="/about-us">About Us</Link>
                </li>
              </ul>

              <ul>
                <li className="font-bold text-white text-2xl mt-5 block">
                  Contacts
                </li>
                <li className="my-3 light-text font-light lg:text-lg">
                  <a href="mailto:">Email: </a>
                </li>
                <li className="my-3 light-text font-light lg:text-lg">
                  <a href="tel:">Telephone Number: </a>
                </li>
                <FooterSocials />
              </ul>
            </div>
          </nav>
        </div>
      </footer>

      <div className="footer__dark px-5 py-2 lg:py-5 2xl:px-0">
        <article className="max-width grid grid-cols-1 gap-5 md:grid-cols-2">
          <h4 className="text-white font-bold">
            White Label Trading Platform{" "}
            <span className="block">Copyright 2021 - 2021</span>
          </h4>
          <Link to="/privacy-policy" className="block text-white font-bold">
            Privacy Policy
          </Link>
        </article>
      </div>
    </>
  )
}

const FooterSocials = () => {
  const [socials] = useState(footerSocials)

  return (
    <ul className="grid grid-cols-2 gap-5 place-items-center w-32 mt-10">
      {socials.map(({ id, image, url }) => (
        <li key={id}>
          <a
            href={url}
            target="_blank"
            rel="noopenner noreferrer"
            className="social__icon"
          >
            {image}
          </a>
        </li>
      ))}
    </ul>
  )
}
