import image from "../../images/help-customer.jpg"

export default function HelpCustomer() {
  return (
    <>
      <section className="section hero">
        <h2 className="green">Customer support</h2>

        <div className="md:grid grid-cols-2 gap-5 lg:gap-10 xl:max-w-7xl xl:mx-auto md:items-center md:py-10">
          <article className="mb-10 md:mb-0">
            <img src={image} alt="Customer Support" />
          </article>

          <article>
            <p className="mb-5 lg:mb-10 font-light">
              We are passionate about providing exceptional customer support for
              our customers. We make sure to be very helpful and responsive,
              ensuring you always receive a prompt answer to your inquiries.
            </p>
            <p className="mb-5 lg:mb-10 font-light">
              Our friendly team is always ready to go to extra lengths to help
              you with whatever you are struggling with. Forget all your worries
              - instead, just leave them to us!
            </p>
          </article>
        </div>
      </section>
    </>
  )
}
