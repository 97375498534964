import image from "../images/form.jpg"
import { RequestDemo } from "../utils"

export default function Form() {
  return (
    <>
      <section>
        <form
          id="get-started"
          className="max-width flex flex-col-reverse md:grid md:grid-cols-2 md:gap-10 lg:items-center"
        >
          <div className="py-10 md:py-0 lg:grid lg:grid-cols-2 lg:items-center lg:gap-10">
            <article className="flex flex-col">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name / Company"
                required
                className="bg-transparent text-white py-5 px-1 border-b-2 border-white placeholder-white lg:text-lg mb-5"
              />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email address"
                required
                className="bg-transparent text-white py-5 px-1 border-b-2 border-white placeholder-white lg:text-lg mb-5"
              />
              <input
                type="phone"
                name="phone"
                id="phone"
                placeholder="Phone number"
                required
                className="bg-transparent text-white py-5 px-1 border-b-2 border-white placeholder-white lg:text-lg mb-5"
              />
            </article>

            <article className="mt-5">
              <RequestDemo />
            </article>
          </div>

          <div>
            <img src={image} alt="form" className="w-full" />
          </div>
        </form>
      </section>
    </>
  )
}
