import { Form } from "../components"
import HelpCards from "./components/HelpCards"
import HelpCustomer from "./components/HelpCustomer"
import HelpHero from "./components/HelpHero"

export default function Help() {
  return (
    <>
      <section>
        <HelpHero />
        <HelpCards />
        <div className="form__gradient section">
          <h2 className="max-width">
            Need help? <span className="block">Send us a message!</span>
          </h2>
          <Form />
        </div>
        <HelpCustomer />
      </section>
    </>
  )
}
