import { useState } from "react"
import { tradeConvert } from "../../data/data"
import plugins from "../../images/trade-plugins.svg"
import feed from "../../images/trade-feed.svg"
import metatrader from "../../images/trade-metatrader.svg"
import crm from "../../images/trade-crm.svg"
import arrow from "../../images/arrow-right.svg"

export default function TradeConvert() {
  const [cards] = useState(tradeConvert)

  return (
    <>
      <section className="section max-width">
        <h2 className="green">Convert Leads Into Paying Customers</h2>
        <p className="text-center bg-gray-100 px-1 pt-2 lg:max-w-4xl lg:mx-auto">
          Not satisfied with a mediocre MT4 or MT5 platform? Trade smarter
          thanks to the advanced web-based technology our WebTrader offers.
          Trade live market data seamlessly through your MT4/MT5. Be in control
          with customizability and advanced charting capabilities. Our powerful
          charting software is the most advanced on the market.
        </p>
        <p className="text-center mb-10 lg:mb-20 bg-gray-100 px-1 py-2 lg:max-w-4xl lg:mx-auto">
          Our WebTrader platform is simple and efficient. It allows you to trade
          the markets no matter where you are. Our advanced charting{" "}
          capabilities allow you to be more profitable with your trades. With
          customizable settings, our charting software is exactly what you need
          to keep yourself ahead of the game.
        </p>

        <div>
          {cards.map(({ id, title, desc, image }) => (
            <article key={id} className="tech__web__article mb-20 lg:mb-40">
              <div className="image">
                <img src={image} alt={title} />
              </div>
              <div className="text">
                <h3 className="green font-light my-6 md:text-4xl lg:mt-0 lg:mb-5">
                  {title}
                </h3>
                <p>{desc}</p>
              </div>
            </article>
          ))}
        </div>

        <section className="trade__cards lg:mb-20">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:items-start lg:place-items-center relative">
            <article className="mb-10 sm:my-0">
              <ul className="grid grid-cols-1 gap-5">
                <li className="green-card">
                  <img src={plugins} alt="Plugins" className="block mx-auto" />
                </li>
                <li className="green-card">
                  <img src={feed} alt="Feed API" className="block mx-auto" />
                </li>
              </ul>

              <div className="arrow arrow-1 sm:hidden">
                <img src={arrow} alt="" />
              </div>
              <div className="arrow arrow-2 sm:hidden lg:block">
                <img src={arrow} alt="" />
              </div>

              <div className="arrow arrow-5 hidden lg:block">
                <img src={arrow} alt="" />
              </div>
              <div className="arrow arrow-6 hidden lg:block">
                <img src={arrow} alt="" />
              </div>
            </article>

            <article className="green-card mt-20 mb-20 relative sm:my-0">
              <img
                src={metatrader}
                alt="MetaTrader 4"
                className="block mx-auto lg:w-72 lg:h-40"
              />

              <div className="top-28 arrow arrow-1 sm:hidden arrow-3 lg:block">
                <img src={arrow} alt="" />
              </div>
              <div className="top-28 arrow arrow-2 sm:hidden arrow-4 lg:block">
                <img src={arrow} alt="" />
              </div>
            </article>

            <article className="crm green-card mt-10 sm:my-0">
              <img
                src={crm}
                alt="CRM"
                className="block mx-auto lg:w-80 lg:h-16"
              />
            </article>
          </div>
        </section>
      </section>
    </>
  )
}
