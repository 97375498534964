import { useState } from "react"
import { homeSlides } from "../data/data"

export default function HomeSlides() {
  const [slides] = useState(homeSlides)
  const [value, setValue] = useState(0)

  const { title, image, desc, list } = slides[value]

  return (
    <>
      <div className="bg-gray-100">
        <section className="section max-width">
          <ul className="grid grid-cols-1 gap-5 pb-10 place-items-center md:flex md:flex-wrap md:items-center md:justify-center">
            {slides.map((slide, index) => (
              <li key={index} className="lg:mr-20 lg:mb-5">
                <button
                  onClick={() => setValue(index)}
                  className={`button__slides lg:w-64 ${
                    index === value && "active"
                  }`}
                >
                  {slide.button}
                </button>
              </li>
            ))}
          </ul>

          <div>
            <h2 className="green lg:w-1/2 lg:mx-auto text-center">{title}</h2>

            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:gap-10 md:items-center py-10">
              <article>
                <img src={image} alt={title} className="image__shadow" />
              </article>

              <article>
                <p className="mb-5 lg:mb-10">{desc}</p>
                <ul>
                  {list.map((l, index) => (
                    <li key={index} className="font-bold my-2 lg:text-lg">
                      {l}
                    </li>
                  ))}
                </ul>
              </article>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
